<template>
  <div>
    <el-page-header
      class="u-bottom-margin--2x"
      :title="$t('GlobalsBackBtn')"
      :content="`${$t('DistributerDetailsPageTitle')} ${distributerId}`"
      @back="goBack"
    >
    </el-page-header>

    <!-- ********************************************************* -->
    <MetricFilterBox
      :defaultFilterData="metricDefaultData"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="true"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            v-if="metric.key == 'isAssignedToEntity'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="metric.displayedData ? metric.displayedData : filters.state"
            @change="filtersChanged(metric.key, $event)"
            @enterClicked="filterEnterClicked(metric.key, filters[metric.key])"
            :meta="metric.meta ? metric.meta : null"
          ></component>
          <component
            v-if="metric.key == 'isInStock'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="metric.displayedData ? metric.displayedData : filters.state"
            @change="filtersChanged(metric.key, $event)"
            @enterClicked="filterEnterClicked(metric.key, filters[metric.key])"
            :meta="metric.meta ? metric.meta : null"
          ></component>
          <component
            v-if="metric.key == 'isOffer'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="metric.displayedData ? metric.displayedData : filters.state"
            @change="filtersChanged(metric.key, $event)"
            @enterClicked="filterEnterClicked(metric.key, filters[metric.key])"
            :meta="metric.meta ? metric.meta : null"
          ></component>
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
            :meta="metric.meta ? metric.meta : {}"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>
    <!-- filters Area -->
    <!-- ********************************************************* -->

    <div>
      <span class="copyWrapper">
        <el-select
          :class="
            $store.state.isRTL ? ' u-left-margin--2x' : ' u-right-margin--2x'
          "
          filterable
          v-model="copiedProvider"
          v-if="lookups"
          size="mini"
          :disabled="false"
          :placeholder="
            $t('DistributerDetailsPageCopyProviderPortfolioSelectPlaceholder')
          "
        >
          <el-option
            v-for="(item, key) in distributersArray"
            :key="key"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          :disabled="copiedProvider == ''"
          type="success"
          size="mini"
          @click="copyPortfolio()"
          >{{ $t("DistributerDetailsPageCopyProviderPortfolioBtn") }}</el-button
        >
      </span>
    </div>
    <!-- ********************************************************* -->
    <!-- table Area -->
    <!-- ********************************************************* -->

    <div>
      <!-- <div class="product-list-filter">
        <el-select
          v-model="filters.trademark"
          filterable
          clearable
          v-if="lookups"
          :placeholder="$t('DistributerDetailsPageFilterTrademarksPlaceholder')"
          @change="filtersChanged('trademark', $event)"
        >
          <el-option
            v-for="(item, key) in trademarksLookup"
            :key="key"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-select
          filterable
          clearable
          v-if="lookups"
          v-model="filters.manufacturer"
          :placeholder="
            $t('DistributerDetailsPageFilterManufacturerPlaceholder')
          "
          @change="filtersChanged('manufacturer', $event)"
        >
          <el-option
            v-for="(item, key) in manufacturerLookup"
            :key="key"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-input
          :placeholder="
            $t('DistributerDetailsPageFilterProductNamePlaceholder')
          "
          v-model="filters.name"
          @change="filtersChanged('name', $event)"
        >
        </el-input>
        <el-input
          type="number"
          clearable=""
          :placeholder="`Product ID`"
          v-model="filters.id"
          @change="filtersChanged('id', $event)"
        >
        </el-input>
        <el-radio-group
          style="display: flex"
          v-model="filters.isAssignedToEntity"
          @change="filtersChanged('isAssignedToEntity', $event)"
        >
          <el-radio-button :label="true">assigned </el-radio-button>
          <el-radio-button :label="false"> unassigned </el-radio-button>
        </el-radio-group>
        <el-select
          v-model="filters.isInStock"
          clearable
          @change="filtersChanged('isInStock', $event)"
          placeholder="Stock"
        >
          <el-option label="In Stock" :value="true"></el-option>
          <el-option label="Out Of Stock" :value="false"></el-option>
        </el-select>
        <el-select
          v-model="filters.isOffer"
          clearable
          @change="filtersChanged('isOffer', $event)"
          placeholder="Is Offer"
        >
          <el-option label="Yes" :value="true"></el-option>
          <el-option label="No" :value="false"></el-option>
        </el-select>
        <template>
          <el-input
            v-model="filters.price.from"
            placeholder="Cash Price From"
            size="normal"
            clearable
            type="number"
            @change="filtersChanged('price ', $event)"
          ></el-input>
          <el-input
            v-model="filters.price.to"
            placeholder="Cash Price To"
            size="normal"
            clearable
            type="number"
            @change="filtersChanged('price ', $event)"
          ></el-input>
        </template> -->
      <!-- <el-radio-group
          v-model="filters.assignment"
          size="normal"
          @change="tabChanged"
        >
          <el-radio-button label="assigned">
            assigned
          </el-radio-button>
          <el-radio-button label="unassgind">
            unassigned
          </el-radio-button>
        </el-radio-group> -->
      <!-- </div> -->

      <div
        class="u-display-flex u-justify-content--center u-bottom-margin--2x"
        v-if="newPortofolioItems.length"
      >
        <el-button type="primary" @click="saveProviderPortoflio">
          {{
            $t("DistributerDetailsPageSaveProductsBtn", [
              newPortofolioItems.length,
            ])
          }}
        </el-button>
      </div>
      <div
        class="u-display-flex u-justify-content--between u-algin-items--center u-bottom-margin--2x"
      >
        <el-upload
          class="u-right-margin--2x u-left-margin--2x"
          :on-change="uploadSheetFileChanged"
          :on-remove="removeSheetFile"
          action="#"
          :multiple="false"
          :auto-upload="false"
          :file-list="sheetFileList"
          accept=".xlsx, .csv"
        >
          <el-button slot="trigger" size="mini" type="primary">
            {{ $t("GlobalSelectFileButtonLabel") }}
          </el-button>
          <el-dropdown size="medium" @command="handleSheetImport">
            <el-button
              :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
              size="mini"
              icon="el-icon-top"
              type="info"
              plain
              :disabled="sheetFileList.length == 0"
              :title="sheetFileList.length == 0 ? 'true' : 'false'"
            >
              {{ $t("GlobalImportExcelButtonLabel") }}
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="create">Add</el-dropdown-item>
              <el-dropdown-item command="update">Update</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-upload>
        <div>
          <el-button
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
            @click="handleSheetExport('update')"
          >
            {{ $t("GlobalExportFileButtonLabel") }}
          </el-button>
          <el-button
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
            @click="handleSheetExport('create')"
          >
            {{ $t("GlobalTemplateDownloadButtonLabel") }}
          </el-button>
        </div>
        <a style="display: none" ref="sheet-download" target="_blank"></a>
      </div>
      <div class="u-display-flex u-justify-content--center u-bottom-margin--2x">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :total="productsPagination.totalResultsCount"
          :current-page="currentPage"
          :page-size="50"
          @current-change="loadMoreProducts"
        >
        </el-pagination>
      </div>
      <!-- class="is-infinite-load" -->
      <el-table
        :data="productsList"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
        @select="setSelectedItems"
        @select-all="setSelectedItems"
        :span-method="objectSpanMethod"
      >
        <el-table-column
          type="selection"
          :selectable="canSelectRow"
        ></el-table-column>
        <el-table-column
          fixed
          prop="image"
          :label="$t('DistributerDetailsPageDetailsColumnTitle')"
          width="300"
        >
          <template slot-scope="scope">
            <div class="u-display-flex u-align-items--center">
              <div
                :class="
                  $store.state.isRTL
                    ? 'u-left-margin--2x '
                    : 'u-right-margin--2x'
                "
              >
                <img
                  width="100"
                  height="100"
                  style="object-fit: scale-down"
                  :src="scope.row.image"
                />
              </div>
              <div class="u-direction--rtl">
                <div class="u-font--medium">
                  {{ scope.row.name }}
                </div>
                <div
                  :class="`u-display-flex ${
                    $store.state.isRTL ? 'u-direction--rtl' : 'u-direction--ltr'
                  }`"
                >
                  <!-- <div
                    :class="
                      $store.state.isRTL
                        ? 'u-left-padding--half-x'
                        : 'u-right-padding--half-x'
                    "
                  >
                    {{ scope.row.unit }}
                  </div> -->
                  <!-- <div class="u-direction--rtl">
                    {{ scope.row.itemsPerUnit }}
                  </div> -->
                </div>
                <div class="u-font--xsmall">
                  ID: <strong>{{ scope.row.id }}</strong>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="120" :label="`Unit`">
          <template slot-scope="scope">
            <div class="u-text--center">
              {{ getUnitName(scope.row.unitId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('DistributerDetailsPageCodeColumnTItle')"
          min-width="220"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="$t('DistributerDetailsPageInputCodeLabel')"
              v-model="scope.row.code"
              @change="
                handlePotrofoliProductChange({ code: $event }, scope.row)
              "
            >
            </el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('DistributerDetailsPageFaturaWeightFactorColumnTItle')"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="
                $t('DistributerDetailsPageFaturaWeightFactorInputPlaceholder')
              "
              v-model="scope.row.countFactor"
              @change="
                handlePotrofoliProductChange({ countFactor: $event }, scope.row)
              "
            >
            </el-input>
          </template>
        </el-table-column> -->
        <el-table-column
          :label="$t('DistributerDetailsPageMaxPerOrderColumnTitle')"
          min-width="220"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="
                $t('DistributerDetailsPageMaxPerOrderInputPlaceholder')
              "
              v-model="scope.row.maxPerOrder"
              @change="
                handlePotrofoliProductChange({ maxPerOrder: $event }, scope.row)
              "
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="Min Per Order" min-width="220">
          <template slot-scope="scope">
            <el-form
              ref="productFieldsForm"
              :rules="productFieldsFormRules"
              :model="scope.row"
            >
              <el-form-item prop="minPerOrder">
                <el-input
                  placeholder="Min Per Order"
                  v-model="scope.row.minPerOrder"
                  @change="
                    handlePotrofoliProductChange(
                      { minPerOrder: $event },
                      scope.row
                    )
                  "
                >
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          prop="originalPrice"
          :label="$t('DistributerDetailsPageCashPriceColumnTitle')"
          min-width="220"
        >
          <template slot-scope="scope">
            <el-input
              :placeholder="
                $t('DistributerDetailsPageCashPriceInputPlaceholder')
              "
              v-model="scope.row.price"
              @change="
                handlePotrofoliProductChange({ price: $event }, scope.row)
              "
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="220" :label="`Total Quantities `">
          <template slot-scope="scope">
            <div class="u-text--center">
              {{ scope.row.totalQuantity }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="220" :label="`Booked Quantities`">
          <template slot-scope="scope">
            <div class="u-text--center">
              {{ scope.row.holdQuantity }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="availableQuantity"
          label="Quantity"
          min-width="220"
        >
          <template slot-scope="scope">
            <el-form
              ref="productFieldsForm"
              :rules="productFieldsFormRules"
              :model="scope.row"
            >
              <el-form-item prop="availableQuantity">
                <el-input
                  placeholder="Quantity"
                  v-model="scope.row.availableQuantity"
                  @change="
                    handlePotrofoliProductChange(
                      { availableQuantity: $event },
                      scope.row
                    )
                  "
                >
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="Offer Price" min-width="120">
          <template slot-scope="scope">
            <div class="u-text--center">
              {{ scope?.row?.offerPrice }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="220" :label="`Options`">
          <template slot-scope="scope">
            <div>
              <div class="u-text--center">
                <el-switch
                  v-model="scope.row.isInStock"
                  :active-text="
                    $t(
                      'DistributerDetailsPageProductAvailabilitySwitchActiveValue'
                    )
                  "
                  :inactive-text="
                    $t(
                      'DistributerDetailsPageProductAvailabilitySwitchInActiveValue'
                    )
                  "
                  :active-value="1"
                  :inactive-value="0"
                  @click.native="removeOffer(scope.row)"
                  @change="
                    handlePotrofoliProductChange({ inStock: $event }, scope.row)
                  "
                >
                </el-switch>
              </div>
              <div class="u-text--center">
                <el-switch
                  disabled
                  @click.native="
                    checkMaxOfferdAlreadyReached(
                      itemsinOffer,
                      scope.row.isOffer
                    )
                  "
                  v-model="scope.row.isOffer"
                  :active-text="
                    $t('DistributerDetailsPageProductInOfferSwicthActiveValue')
                  "
                  :inactive-text="
                    $t(
                      'DistributerDetailsPageProductInOfferSwicthInActiveValue'
                    )
                  "
                  :active-value="1"
                  :inactive-value="0"
                  @change="
                    handlePotrofoliProductChange({ isOffer: $event }, scope.row)
                  "
                >
                </el-switch>
              </div>
              <!-- <div class="u-text--center">
                <el-switch
                  v-model="scope.row.isFaturaProduct"
                  :active-text="
                    $t('DistributerDetailsPageProductFaturaProductLabel')
                  "
                  :active-value="1"
                  :inactive-value="0"
                  @change="
                    handlePotrofoliProductChange(
                      { isFaturaProduct: $event },
                      scope.row
                    )
                  "
                >
                </el-switch>
              </div> -->
              <div class="u-text--center">
                <span class="u-pillar-margin--1x">{{
                  $t("DistributerDetailsPageProductFaturaProductLabel")
                }}</span>
                <el-checkbox
                  v-model="scope.row.isFaturaProduct"
                  :true-label="1"
                  :false-label="0"
                  @change="
                    handlePotrofoliProductChange(
                      { isFaturaProduct: $event },
                      scope.row
                    )
                  "
                >
                </el-checkbox>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('DistributerDetailsPageProductInOfferColumnTitle')"
          min-width="150"
        >
          <template slot-scope="scope">
            <div class="u-text--center">
              <el-switch
                :disabled="
                  !scope.row.isInStock ||
                  (itemsinOffer >= OfferLimit && !scope.row.isOffer)
                "
                @click.native="
                  checkMaxOfferdAlreadyReached(itemsinOffer, scope.row.isOffer)
                "
                v-model="scope.row.isOffer"
                :active-text="
                  $t('DistributerDetailsPageProductInOfferSwicthActiveValue')
                "
                :inactive-text="
                  $t('DistributerDetailsPageProductInOfferSwicthInActiveValue')
                "
                :active-value="1"
                :inactive-value="0"
                @change="
                  handlePotrofoliProductChange({ isOffer: $event }, scope.row)
                "
              >
              </el-switch>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          min-width="100"
          :label="$t('DistributerDetailsPageProductFaturaProductLabel')"
        >
          <template slot-scope="scope">
            <div class="u-text--center">
              <el-checkbox
                v-model="scope.row.isFaturaProduct"
                :true-label="1"
                :false-label="0"
                @change="
                  handlePotrofoliProductChange(
                    { isFaturaProduct: $event },
                    scope.row
                  )
                "
              >
              </el-checkbox>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          min-width="100"
          :label="$t('DistributerDetailsPageTableRemoveProductColumnLabel')"
        >
          <template slot-scope="scope">
            <div class="u-text--center" v-if="scope.row.isInPortofolio">
              <el-button
                size="medium"
                icon="el-icon-delete"
                circle
                style="color: red"
                @click="removeProductClicked(scope.row)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="u-display-flex u-justify-content--center u-top-margin--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="productsPagination.totalResultsCount"
        :current-page="currentPage"
        :page-size="50"
        @current-change="loadMoreProducts"
      >
      </el-pagination>
    </div>
    <transition name="el-fade-in-linear">
      <div
        class="bulk-action-button-holder"
        v-show="selectedItemsForDeletion.length > 0"
      >
        <div class="bulk-action-button-holder__parent">
          <el-button
            type="danger"
            size="medium"
            @click="deleteProductsWrapper(selectedItemsForDeletion)"
            >{{ $t("DistributerDetailsPageDeleteProductsBtnLabel") }}</el-button
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { forEach, each, merge, find, extend } from "lodash";
import { providersService } from "../services/providers.service";
import { adminActions } from "../services";
import { mapGetters } from "vuex";
import { utils } from "../utils";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import tradeMarkMetric from "../components/transaction/metricboxComponents/tradeMarkMetric.vue";
import manufacturerMetric from "../components/transaction/metricboxComponents/manufacturerMetric.vue";
import _ from "lodash";
import dynamicDropdownMetric from "../components/transaction/metricboxComponents/dynamicDropdownMetric.vue";
import inputMetric from "../components/transaction/metricboxComponents/inputMetric.vue";
import dynamicTypeMetric from "../components/transaction/metricboxComponents/dynamicTypeMetric.vue";
export default {
  name: "DistributerDetails-ProviderPortfolio",
  components: {
    metricbox,
    MetricFilterBox,
    inputMetric,
    tradeMarkMetric,
    dynamicDropdownMetric,
    manufacturerMetric,
    dynamicTypeMetric,
  },
  data() {
    return {
      productFieldsFormRules: {
        availableQuantity: [
          {
            pattern: /^[1-9]\d*$/,
            message: "Please enter a positive integer ",
            trigger: "change",
          },
        ],
        minPerOrder: [
          {
            pattern: /^[1-9]\d*$/,
            message: "Please enter a positive integer ",
            trigger: "change",
          },
        ],
      },
      filters: {},
      metricsList: [],
      metricDefaultData: [
        {
          key: "trademark",
          label: "Trade Mark",
          compName: "tradeMarkMetric",
        },
        {
          key: "manufacturer",
          label: "Manufacturer",
          compName: "manufacturerMetric",
        },
        {
          key: "id",
          label: "Product ID",
          compName: "inputMetric",
        },
        {
          key: "name",
          label: "Product Name",
          compName: "inputMetric",
        },
        {
          key: "isAssignedToEntity",
          label: "Assigned Status",
          compName: "dynamicTypeMetric",
          displayedData: [
            {
              value: "Assigned",
            },
            {
              value: "Unassigned",
            },
          ],
        },
        {
          key: "isInStock",
          label: "Stock Status",
          compName: "dynamicTypeMetric",
          displayedData: [
            {
              value: "In Stock",
            },
            {
              value: "Out Of Stock",
            },
          ],
        },
        {
          key: "isOffer",
          label: "Offer Status",
          compName: "dynamicTypeMetric",
          displayedData: [
            {
              value: "Yes",
            },
            {
              value: "No",
            },
          ],
        },
        {
          key: "from",
          label: "Price From",
          compName: "inputMetric",
        },
        {
          key: "to",
          label: "Price To",
          compName: "inputMetric",
        },
      ],
      copiedProvider: "",
      productsList: [],
      providerPortofolio: [],
      productsPagination: {},
      noMore: false,
      isLoadMore: false,
      OfferLimit: 0,
      configLimit: 0,
      filterTraceMark: null,
      filterManufacturer: null,
      // filters: { price: { from: undefined, to: undefined } },
      providerProfile: {},
      newPortofolioItems: [],
      sheetFileList: [],
      selectedItemsForDeletion: [],
    };
  },
  mounted() {
    this.getProfile()
      .then(this.getProducts(this.filters, this.currentPage))
      .then(this.getOffersLimit);
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? parseInt(currentPageQuery) : 1;
    },
    distributerId() {
      return this.$route.params.id;
    },
    itemsinOffer() {
      let itemsInOffer = 0;
      forEach(this.productsList, (product) => {
        if (product.isOffer) itemsInOffer++;
      });
      return itemsInOffer;
    },
    distributersArray() {
      let currentLookups =
        this.lookups && this.lookups.distributers
          ? this.lookups.distributers
          : [];
      return currentLookups;
    },
    unitsLookup() {
      let returnedArray = [];
      const currentLookups = this.lookups;
      if (currentLookups && currentLookups.unitsMain) {
        returnedArray = JSON.parse(JSON.stringify(currentLookups.unitsMain));
      }
      return returnedArray;
    },
    trademarksLookup() {
      let currentTrademarks =
        this.lookups && this.lookups.trademarks ? this.lookups.trademarks : [];
      // return [];
      return currentTrademarks;
    },
    manufacturerLookup() {
      let currentManufacturers =
        this.lookups && this.lookups.manufacturer
          ? this.lookups.manufacturer
          : [];
      // return [];
      return currentManufacturers;
    },
  },
  created() {
    const metricsSession = JSON.parse(
      localStorage.getItem("dist-details-metrics")
    );
    if (metricsSession) {
      this.metricsList = metricsSession;
    }
  },

  methods: {
    // ***************************************************
    // filters Area
    // ***************************************************
    filtersChanged(key, val) {
      if (val !== 0 && val !== false && _.isEmpty(val)) {
        this.filters[key] = undefined;
      }
      this.clearCheckedItems();
      this.getProducts(this.filters);
    },

    saveMetircs() {
      localStorage.setItem(
        "dist-details-metrics",
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      //fix lodash reactivity issue
      this.metricsList = [...this.metricsList];
    },
    clearFilters() {
      this.filters = {};
      this.filtersChanged();
    },
    goBack() {
      this.$router.go(-1);
    },
    /* ************************************************************* */
    // filters area
    /* ************************************************************* */

    // filtersChanged(key, value) {
    //   if (key === "isAssignedToEntity" && !value) {
    //     this.filters.price = { from: undefined, to: undefined };
    //   }
    //   if (key === "isInStock" && value === "") {
    //     this.filters.isInStock = undefined;
    //   }
    //   if (key === "isOffer" && value === "") {
    //     this.filters.isOffer = undefined;
    //   }

    //   if (key === "id" && !value.length) {
    //     delete this.filters.id;
    //   }
    //   this.clearCheckedItems();
    //   this.getProducts(this.filters);
    // },
    /* ************************************************************* */
    /* offers handling logic
    /* ************************************************************* */

    async getOffersLimit() {
      try {
        const offerResonse = await adminActions.getConfig("wholesaler");
        this.configLimit = offerResonse.data.config.Wholesaler_Offers_Limit;
        this.MaxofferInPage(offerResonse.data.config.Wholesaler_Offers_Limit);
      } catch (err) {
        this.$notify({
          title: this.$t("DistributerDetailsPageErrorNotificationTitle"),
          message: err.response.data.error,
          type: "error",
        });
      }
    },
    checkMaxOfferdAlreadyReached(itemsinOffer, isItemInOffer) {
      if (itemsinOffer == this.OfferLimit && isItemInOffer == 0)
        this.$notify({
          title: this.$t("DistributerDetailsPageErrorNotificationTitle"),
          message: this.$t(
            "DistributerDetailsPageMaxOfferReachedNotificationMessage",
            [this.configLimit]
          ),
          type: "error",
        });
    },
    MaxofferInPage(limit) {
      let itemsInOffer = 0;
      let allitemsInOffer = 0;
      forEach(this.productsList, (product) => {
        if (product.isOffer) itemsInOffer++;
      });
      forEach(this.providerPortofolio, (product) => {
        if (product.isOffer) allitemsInOffer++;
      });

      this.OfferLimit = limit - (allitemsInOffer - itemsInOffer);
    },
    removeOffer(product) {
      if (!product.isInStock) product.isOffer = false;
    },
    /* ************************************************************* */
    /* copyPortfolio logic
    /* ************************************************************* */
    async copyPortfolio() {
      this.$confirm(
        this.$t("DistributerDetailsPageCopyProviderWarningMessage"),
        this.$t("DistributerDetailsPageCopyProviderWarningTitle"),
        {
          confirmButtonText: this.$t(
            "DistributerDetailsPageCopyProviderWarningOKLabel"
          ),
          cancelButtonText: this.$t(
            "DistributerDetailsPageCopyProviderWarningCancelLabel"
          ),
          type: "warning",
        }
      )
        .then(async () => {
          // this.$loading();
          try {
            await providersService.copyProviderProfile(
              this.copiedProvider,
              this.distributerId
            );
            // this.$loading().close();

            this.getProfile().then(this.getProducts);
          } catch (err) {
            console.log(err);
            // this.$loading().close();
          }
        })
        .catch(() => {});
    },
    /* ************************************************************* */
    /* products  logic
    /* ************************************************************* */

    async getProducts(filters, page = 1) {
      // const pageLoading = this.$loading();
      filters.entityId = this.distributerId;
      await providersService
        .getProductsSeperateUnits(filters, page)
        .then((res) => {
          let productsList = res.products.sort((a, b) => {
            if (a.id < b.id) {
              return -1;
            }
            if (a.id > b.id) {
              return 1;
            }
            return 0;
          });
          let finalList = [];
          each(productsList, (product) => {
            const mappedProductInfo = find(this.providerPortofolio, {
              productId: product.id,
              unitId: product.unitId,
            });

            if (mappedProductInfo) {
              mappedProductInfo.id = undefined;
              (mappedProductInfo.isInPortofolio = true),
                merge(product, mappedProductInfo);
              finalList.push(product);
            }
          });
          this.productsList = productsList;
          // this.productsList = finalList;
          this.productsPagination = res.pagination;
        });
    },
    loadMoreProducts(page) {
      this.clearCheckedItems();
      this.$router.push({
        query: {
          page: page,
        },
      });
      // this.getProducts(this.filters, page).then(this.getOffersLimit);
    },

    getProfile() {
      return providersService
        .getProviderProfile(this.distributerId)
        .then((providerProfile) => {
          this.providerProfile = providerProfile;
          this.providerPortofolio = providerProfile.products;
        });
    },
    handlePotrofoliProductChange(updatedAttribute, itemScope) {
      let updatedProtfolioItem = find(this.providerPortofolio, {
        productId: itemScope.id,
        unitId: itemScope.unitId,
      });
      let updatedProductItem = find(this.newPortofolioItems, {
        product: itemScope.id,
        unitId: itemScope.unitId,
      });

      if (!updatedProductItem) {
        let addedProductItem = {
          product: itemScope.id,
          unitId: itemScope.unitId,
        };

        // if the change item is already in provider portofolio
        if (updatedProtfolioItem) {
          merge(addedProductItem, {
            code: updatedProtfolioItem.code,
            // countFactor: updatedProtfolioItem.countFactor,
            price: updatedProtfolioItem.price,
            inStock: updatedProtfolioItem.isInStock,
            maxPerOrder: updatedProtfolioItem.maxPerOrder,
            isFaturaProduct: updatedProtfolioItem.isFaturaProduct,
            availableQuantity: updatedProtfolioItem.availableQuantity,
          });
        }

        extend(addedProductItem, updatedAttribute);
        this.newPortofolioItems.push(addedProductItem);
      } else {
        extend(updatedProductItem, updatedAttribute);
      }
    },
    async saveProviderPortoflio() {
      try {
        const response = await providersService.updateProviderPortofolio({
          providerId: this.distributerId,
          products: this.newPortofolioItems,
        });
        if (response) {
          this.newPortofolioItems = [];
          await this.getProfile();
          await this.getProducts(this.filters, this.currentPage);
        }
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
    tableRowClassName(options) {
      const { row } = options;
      // const providerItems = this.providerPortofolio.filter(
      //   (item) => item.productId === row.id
      // );
      // if (providerItems && providerItems.length > 0) {
      //   return "u-bg--green-lightest no-hover";
      // }
      if (row.isInPortofolio) {
        return "u-bg--green-lightest no-hover";
      }

      return "no-hover";
    },
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(chosenAction) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      // this.$loading();
      try {
        const response = await providersService.importProviderPortfolio(
          toBeSentForm,
          chosenAction
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
        // this.$loading().close();
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
        // this.$loading().close();
      }
      this.sheetFileList = [];
    },
    async handleSheetExport(chosenAction) {
      // this.$loading();
      const isCreate = chosenAction === "create";
      const entityId = isCreate ? null : this.distributerId;
      try {
        const response = await providersService.exportProviderSheet(
          chosenAction,
          entityId
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = isCreate
            ? "DistributerProductTemplate.xlsx"
            : `DistributerPortfolio_${this.providerProfile.name}.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
        // this.$loading().close();
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
        // this.$loading().close();
      }
    },
    setSelectedItems(itemsList) {
      this.selectedItemsForDeletion = itemsList;
    },
    removeProductClicked(item) {
      this.deleteProductsWrapper([item]);
    },
    generateHtmlData(productsArray) {
      let outputString = ` <div><p>${this.$t(
        "DistributerDetailsPageProductsDeletionPopupLabel"
      )}</p><ul>`;
      productsArray.forEach((item) => {
        if (item && item.name) {
          outputString += `<li>${item.name} (${this.getUnitName(
            item.unitId
          )})</li>`;
          console.log("item", JSON.parse(JSON.stringify(item)));
        }
      });
      outputString += ` </ul></div> `;
      return outputString;
    },
    async deleteProductsWrapper(productsArray) {
      const htmlOutput = this.generateHtmlData(productsArray);
      this.$confirm(htmlOutput, "Warning", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        cancelButtonText: this.$t("GlobalCancelBtn"),
        type: "Warning",
        dangerouslyUseHTMLString: true,
        lockScroll: false,
      })
        .then(async () => {
          try {
            const userId = this.providerProfile.id;
            const productIdsArray = productsArray.map((item) => {
              return { productId: item.id, unitId: item.unitId };
            });
            const response = await providersService.removeProductsFromPortfolio(
              userId,
              productIdsArray
            );
            if (response && response.status == 200) {
              this.popupMessageWrapper(
                this.$t("DistributerDetailsPageProductsDeletionSuccessMessage"),
                "success",
                2000
              );
              setTimeout(async () => {
                this.clearCheckedItems();
                await this.getProfile();
                await this.getProducts(this.filters, this.currentPage);
              }, 1500);
            }
          } catch (error) {
            console.log("productDeletionError", error);
            const errorMessage = this.errorMessageExtractor(error);
            // this.popupMessageWrapper(errorMessage, "error", 2000);
            this.$message({
              message: errorMessage,
              type: "error",
              duration: 0,
              showClose: true,
            });
          }
        })
        .catch(() => {});
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t("GlobalErrorMessage");
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    canSelectRow(row) {
      return row.isInPortofolio;
    },
    clearCheckedItems() {
      this.selectedItemsForDeletion = [];
    },
    getUnitName(unitId) {
      const foundItem = find(this.unitsLookup, { id: unitId });
      if (foundItem && foundItem.name) {
        return foundItem.name;
      }
      return "no data";
    },
    objectSpanMethod(options) {
      const { row, columnIndex } = options;
      if (columnIndex === 1) {
        const currentProductItems = this.productsList.filter(
          (item) => item.id === row.id
        );
        if (currentProductItems && currentProductItems.length > 1) {
          const currentObjectIndex = currentProductItems.findIndex(
            (item) => item.id === row.id && item.unitId == row.unitId
          );
          if (currentObjectIndex === 0) {
            return {
              rowspan: currentProductItems.length,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 1,
              colspan: 0,
            };
          }
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
  },
  watch: {
    $route(to) {
      this.getProducts(this.filters, to.query.page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/wrench-kit/settings/_settings.global.scss";

.copyWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
}

.c-distributers-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-distributers-header__title {
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
}
.infinite-list-wrapper {
  height: 90vh;
}

.el-table.is-infinite-load {
  position: inherit !important;
}
.el-input {
  width: 150px !important;
}

.product-list-filter {
  display: flex;
  min-height: 40px;
  align-items: center;
  border: 1px solid $wrench-color-sky;
  margin-bottom: $wrench-spacing-2x;
  padding: 8px;
  gap: 1rem;
}
.bulk-action-button-holder {
  position: absolute;
  top: 5vh;
  right: 5vw;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 250px;
  height: 150px;
  z-index: 2;
  &__parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
[dir="rtl"] {
  .bulk-action-button-holder {
    left: 5vw;
  }
}
.el-message-box {
  overflow-y: auto !important;
  max-height: 100vh !important;
}
</style>
