<template>
  <el-select
    :source="lookups.trademarks ? lookups.trademarks : []"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    filterable
    clearable
    :placeholder="$t('DistributerDetailsPageFilterTrademarksPlaceholder')"
  >
    <el-option
      v-for="(item, key) in lookups.trademarks ? lookups.trademarks : []"
      :key="key"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Array },
  },
  components: {
    // dropdownSelectAll,
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
